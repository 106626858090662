.bubbles-section {
    padding: 20px;
    width: 50%;
}

.nosomos-container {
justify-content: center;    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1fr;
}

.section11 {
    text-align: center;
}

.bubbles-container {
    flex: 1;
    position: relative;
    width: 100%;
    height: 250px; /* Adjust height based on your design */
    margin-top: 20px;
}

.bubble {
    position: absolute;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    opacity: 1; /* Set opacity to 1 to make sure it's visible */
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
}

.bubble-number {
    font-size: 72px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;

}

.bubble-text {
    font-size: 18px;
    text-align: center;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
font-weight: 300;
    padding: 0 10px;
}

.bubble.blue {
    background-color: #0055A5;
    color: white;
    padding: 10px;
    width: 300px;
    height: 175px;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    
}

.bubble.light-blue {
    background-color: #73B0E1;
    color: white;
    width: 250px;
    height: 200px;
    top: 170px;
    left: 30%;
    transform: translateX(-30%);
}

.bubble.beige {
    background-color: #F5DEB3;
    color: #333;
    width: 180px;
    height: 180px;
    top: 250px;
    right: -35%;
    transform: translateX(-70%);
}

/* Media query for smaller viewports */
@media (max-width: 768px) {
    .nosomos-container{
        flex-direction: column;
        margin-left: -100px;
    }
    .section11{
        width: 100%;
        margin: auto;
    }
    .bubbles-container {
        height: auto;
        display: flex;
        flex-direction: column;
        margin:auto;
        justify-content: center;
        left: 20%;
        margin-top: 50px;
        margin-bottom: 50px;

    }

    .bubble {
        position: relative;
        width: 100%;
        max-width: 300px;
        margin: 10px 0;
        transform: none;
    }

    .bubble.blue {
        top: 0;
        left: 80px;
        width: 300px;
    padding: 0;
    }

    .bubble.light-blue {
        top: 0;
        left: 23px;
        width: 300px;
    }

    .bubble.beige {
        top: 0;
        left: 140px;  
        width: 300px;
        margin-bottom: 80px;
    }
}


