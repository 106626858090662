/* Global Styles */
html {
    scroll-behavior: smooth; /* Enable smooth scrolling globally */
}

body {
    background-color:#F7F7F7;
    ;
    overflow-x: hidden;
}

/* Home Container */
.home-container {
    background-color:rgb(242, 245, 247);
    position: relative;

}
.nav-elements{
    display:flex;
    justify-content: flex-start;
    max-width: 1150px;
}
/* Header Styles */
.head-container {
 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 9vh;
border-bottom:1px solid rgba(178, 178, 178, 0.65);    width: 100%;
}

.logo-container {
    align-content: center;
    width: 200px;
    margin-right: 60px;
}
.logo {
    width: 85%;
}

nav {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

nav ul {
    list-style-type: none;
    display: flex;
    gap: 20px;
}

nav ul li {
    cursor: pointer;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: rgba(0, 0, 0, 0.533);
    font-size: 14px;
    align-content: center; 
    line-height: 14px;
    padding: 31px 0px;
}
main{
    display: flex;
    flex-direction: column;
}
/* Content Wrapper */
.content-wrapper {
    max-width: 1050px;
    margin: 0 auto;
    display:flex;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
}
.leftheaderContentContainer{
    padding:22px 0px;
}
/* Intro Section Styles */
.intro {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 711px;
    text-align: left;
  
width:100%;
    margin: -10px 0;
    
}

.contact-options {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px ;
    width: 100%;
}

.contact-option {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0 0px;
text-align: center;
}
.contact-option .Path{
    color:rgb(0,85,165);
    size:60px;
    line-height: 60px;
    font-weight: 900;
    margin-bottom: 25px;
}
.contact-options .contact-option p{
    padding: 0px;
margin:0px;
line-height: 29px;
flex-wrap: wrap; /* Ensure flex items wrap */

font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
font-size: 14px;
font-weight: 500;
color: rgb(102, 102, 102);
line-height: 24px;
width: 100%;

}/* Info Cards Section */
.info-section {
    padding: 100px 0 60px 0;
    background-color:rgb(242, 245, 247);
    margin: 0px auto;
}

.info-cards-row {
    display: flex;
    justify-content: flex-start;
    max-width: 1050px;
    margin: 0 auto;
    margin-bottom: 20px; /* Optional: space between rows */
    padding: 30px 0;
}

.info-card {
    text-align: left; /* Left align text */
    width: 30%;
    margin: 10px 40px 0px 0;
}
.info-card2 {
    text-align: left; /* Left align text */
    width: 50%;
    margin: 10px 40px 0px 0;
}
.info-card2 img {
    width: 80px;
    height: 80px;
}
.info-card img {
    width: 80px;
    height: 80px;
}
.info-card2 h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}
/* CSS for fade-in animation */

.info-card2 p {
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-weight: 300; /* Font weight 300 */
    color: rgb(107, 132, 131); /* Font color */
    font-size: 16px; /* Font size */
    line-height: 24px; /* Line height */
}
.info-card h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.info-card p {
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-weight: 300; /* Font weight 300 */
    color: rgb(107, 132, 131); /* Font color */
    font-size: 16px; /* Font size */
    line-height: 24px; /* Line height */
}
.intro h1 {
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
    color: #121212;
    margin: 0 20px 0 0;
    line-height: 46px;
    font-weight: 500;
    padding:0 0 10px;
    letter-spacing: .05cap;

}
h1 span{
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
    color: #121212;
    margin: 0 20px 0 0;
    line-height: 46px;
    font-weight: 500;
    padding:0 0 10px;
    letter-spacing: .05cap;
    max-width: 2200px;

}
.intro h4 {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: #0055a5;
    font-weight: 700;
    padding:0 0 10px;
    line-height: 21px;
    margin: 0px;

}
.contact-option h4{
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    color:rgb(51, 51, 51);
    font-weight: 500;
    padding:0 0 10px;
    line-height: 21px;
}
.leftheaderContentContainer p{
padding: 0px;
margin:5px 0px;
line-height: 29px;
font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 300;
color: rgb(0, 0, 0);

}

.video-container {
    width: 300px;
    height: 520px;
    border-radius: 36px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: rgb(251, 251, 251);
    position: relative;
    cursor:pointer;
    top:120px;
    left:135px;
    background-image: url('logo.png'); /* Replace with your image path */

    background-repeat: no-repeat;
    background-position: center;
background-size: contain;
}
/* iPhone Video Styles */

  

.iphone-video {
    width: 100%;
    height: 100%;
    border-radius: 36px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.video-container:hover .iphone-video {
    opacity: 1; /* Show on hover for demo purposes */
}

.info-card h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Background Image */
.background-image {
    background-image: url('bg.png'); /* Replace with your image path */
background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
   max-height: 720px;
    flex: 1;
    padding: 70px 0px 0px;
    background-position: right center;
}
.leftside span{
    display: flex;
    margin-top: 100px;
    margin-left: 100px;
}
.leftside{
    flex-direction: column;
    display: flex;
    width: 70%;
    height: auto;

}
.leftside img{
    width: 90%;
    height: 100%;
}
.intro .left {
    max-width: 45%;
}

.right {
    max-width: 50%;
    text-align: center;
}

.right img {
    max-width: 100%;
    height: auto;
}
/* New Map Section */
.map-section {
    padding: 50px 0;
    background-color: #fff;
    width: 100%;
    height: 770px;
    margin: 0 auto;
    text-align: center;
}

.map-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 50px;
    color: #121212;
    margin-bottom: 10px;
    font-weight: 300;

}

.map-section h3 {
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-size: 21px;
    color: #070707;
    margin-bottom: 30px;
    line-height: 24px;
    font-weight: 500;
}

.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 3900px;
}

.map-container iframe {
    width: 100%;
    max-width: 940px;
    height: 480px;
    border: none;
}.quesomos-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin:  0;
}
.mapContainer{
    background-color: white;
    padding:30px;
    padding-bottom: 80px;

}
    .mapContainer h2{
    font-size: 50px;
    font-family: 'Montserrat', sans-serif !important;
 font-weight: 500 !important;
margin-bottom: 10px ;
}
.mapContainer h3{
    font-size: 22px;
    font-family: 'Montserrat', sans-serif !important;
 font-weight: 500 !important;
}

.leftsideimage{
display: flex;
align-items: flex-end;

}


.quesomos-container{
    text-align: left;

    width:1000px;
    max-height: 550px;
    margin: 60px 0;
    margin-bottom: 30px;
display: flex;
flex-direction: row;
    border-radius: 20px;
    height: 550px;
   background:linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
   font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
color: white;
padding: 25px;
align-items: center;
}
.nosomos-container{
    text-align: left;
padding-left: 0;
    width:1050px;
    margin: 60px 0;
display: flex;
flex-direction: row;
    border-radius: 20px;
    height: 600px;
   font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
color: black;

}
/* HomePage.css */

/* Add this to the existing CSS */

  /* Ensure you have the rest of your CSS here */
  
.section11{
width: 50%;
margin: 10px;
margin-left: 20px;
position: relative;
align-items: center;

}
.nosomos-container div p{
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-size: 15px;
font-weight: 200;
line-height: 29px;
text-align: left;

color:
rgb(15, 57, 59);
letter-spacing: 0.05cap;
}
.section11 h2{
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.7rem;
    font-weight: 500;
    margin-top: 1.5rem;
}
.section11 p{
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-size: 1.1rem;
    text-align: start;
    font-weight: 500;
    text-align: left;

}

.textcolum{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.boardrow{
    display: flex;
    flex-direction: row;
    gap: 20px;

}
.section11 img{
    height: 30px;
    width: 30px;
}
.textcolum h4{
    display: flex;
    justify-content: flex-start;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
align-content: top;
margin:0;
font-size: 1.1rem;
}
.nos-section {
    width: 100%;
    background-color:rgb(242, 245, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}
.nuevavision-container{
    text-align: left;

        
  max-width: 1000px;
    margin: 60px 0;
    margin-bottom: 30px;
display: flex;
flex-direction: row;
    border-radius: 20px;
    max-height: 500px;
   background:linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
   font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
color: white;
padding: 50px;
align-items: center;
}
.nueva-section {
    width: 100%;
    background-color:rgb(242, 245, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.section22{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;

}
.section22 img{
width: 100%;
height:auto;
;}
.section11 h4{
text-align: start;
margin-bottom: 0px;
padding: 0%;
color:
rgb(0, 85, 165) !important;
}

 .learn-more-buton{
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
 width: 190px;
}
.learn-more-buton1{
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    bottom: 150px;
    cursor: pointer; /* Pointer cursor on hover */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
 width: 190px;
}
.learn-more-buton:hover {
    background-color: #004080; /* Background color on hover */
    padding-right: 30px; /* Increase padding on the right to make space for the arrow */
}

.learn-more-buton::after {
    content: ">"; /* Right arrow character */
    padding-left: 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Transition for opacity */
}

.learn-more-buton:hover::after {
    opacity: 1; /* Show arrow on hover */
}
.custom-image-container {
    position: relative;
    height: 700px; /* Set the desired height */
    overflow: hidden;
    transition: transform 0.2s ease-out;

}

.custom-scrolling-image {
    position: absolute;
    top: 100px;
   width: 100%;
   transform: translate(-50%, -50%);
   height: auto;
    transition: transform 0.2s ease-out;
}
.custom-overlay-container{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.custom-content-overlay {
    top: 45%;
    margin: 20px auto;
    left: 50%;
    background: white;
    padding: 80px 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 235px;
    width: 960px;
    max-width: 960px; /* Adjust width as necessary */
    max-height: 300px;
    text-align: center;
    z-index: 10;
}
nav ul li a {
    text-decoration: none;
    color: inherit; /* Use the same color as the parent element */
}

nav ul li a:hover {
    color: #0055a5; /* Change color on hover */
}
.nav-menu ul{
    max-width: 100%;
    text-align: center;
    text-decoration: none;
}
.custom-content-overlay h2 {
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
font-size: 50px;
font-weight: 500;
color:rgb(9, 53, 50);
line-height: 58px;
}

.custom-content-overlay p {
    margin-bottom: 20px;
    line-height: 32px;

    color: 
    rgb(15, 57, 59); /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 18px; /* Font size */
    font-weight: 300; /* Font weight */
}

.custom-content-overlay .custom-btn {
   
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 20px;
    display: inline-block;
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */

}



.custom-content-overlay .custom-btn:hover {
    background-color: #004080; /* Background color on hover */
    padding-right: 30px; /* Increase padding on the right to make space for the arrow */
}
main{
    margin-inline: auto;
}
.custom-content-overlay .custom-btn:hover::after {
    content: ">"; /* Right arrow character */
    padding-left: 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Transition for opacity */
}
.custom-content-overlay .custom-btn:hover::after {
    opacity: 1; /* Show arrow on hover */
}
.nueva-section .image-container img{
    width: 1300px;
}
.image-container {
    position: absolute;
    top:400px;
    max-width: 100%;
    height: 1027px;
    max-height: 1027px;
}

.image-container img {
    width: 100%;
    height: auto;
}

.learn-more-button1 {
    text-decoration: none;
    bottom: -225px;
    left:0px;
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    position: absolute; /* Absolute positioning */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
    display: inline-block; /* Display as inline-block */
}
.learn-more-button {
    text-decoration: none;
    bottom: -40px;
    left:50px;
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    position: absolute; /* Absolute positioning */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
    display: inline-block; /* Display as inline-block */
}
.nos-section .learn-more-button {
    bottom: 50px;
    left:60px;
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    position: absolute; /* Absolute positioning */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
    display: inline-block; /* Display as inline-block */
}
.nueva-section .learn-more-button {
    bottom: 230px;
    left:695px;
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    position: absolute; /* Absolute positioning */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
    display: inline-block; /* Display as inline-block */
}

.learn-more-button:hover {
    background-color: #004080; /* Background color on hover */
    padding-right: 30px; /* Increase padding on the right to make space for the arrow */
}

.learn-more-button::after {
    content: ">"; /* Right arrow character */
    padding-left: 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Transition for opacity */
}

.learn-more-button:hover::after {
    opacity: 1; /* Show arrow on hover */
}
.learn-more-button1:hover {
    background-color: #004080; /* Background color on hover */
    padding-right: 30px; /* Increase padding on the right to make space for the arrow */
}
.learn-more-buton{
    text-decoration: none;
}
.learn-more-button1::after {
    content: ">"; /* Right arrow character */
    padding-left: 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Transition for opacity */
}

.learn-more-button1:hover::after {
    opacity: 1; /* Show arrow on hover */
}
/* Media Queries for Tablets */
/* Media Queries for Tablets */
/* Add these styles to your existing CSS */

/* Hide the hamburger button on larger screens */
.hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}.experience-container {
    display: flex;
    width: 65%;
    height: 40%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f3ecd1;
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-column {
    width: 50%;
    padding-right: 20px;
    z-index: 3;
    margin: auto;
}

.right-column {
    width: 50%;
    background-color: #f3ecd1;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.step {
    width: 100%;
    height: 15%;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step h2 {
    margin: 0;
    color: #ffbf00;
    font-size: 36px;
    margin-right: 20px;
    height: 100%;
    font-weight: 500;
}

.step h4 {
    margin: 10px 0 10px 0;
    color:
    rgb(9, 53, 50);
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif
    ;
    font-size: 16px;
    font-weight: 600;
    text-align: left;

}

.step p {
    text-align: left;
    margin: 0;
    color:
    rgb(133, 133, 133);
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    ;
    font-size: 16px;
    font-weight: 100;
line-height: 22px
}

.header h2 {
    color: #0e4f99;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .06cap;
}
.header h1 {
    color: #333;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 500;
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif

}

.header p {
    color: rgba(30, 30, 30, 0.814);
    margin-bottom: 30px;
    max-width: 400px;
    font-size: 16px;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-weight: 100;

}

 .header button {
  
    background-color: #0055a5; /* Background color */
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 17px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    padding: 10px 18px; /* Padding */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
 z-index: 40;
 transition: background-color 0.3s ease, padding 0.3s ease; /* Transition for background color and padding */
 outline: none; /* No outline */
    display: inline-block; /* Display as inline-block */
}

.header button:hover {
    background-color: #004080; /* Background color on hover */
    padding-right: 30px; /* Increase padding on the right to make space for the arrow */
}

.header button::after {
    content: ">"; /* Right arrow character */
    padding-left: 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Transition for opacity */
}

.header button:hover::after {
    opacity: 1; /* Show arrow on hover */
}
.header button:hover {
    background-color: #073c7a;
}


.testimonials-container {
    text-align: center;
    padding: 20px;
  }
  
  .testimonials-wrapper {
    display: flex;
    gap: 20px;
    padding: 20px;
    justify-content: center; /* Center the boxes horizontally */
    flex-wrap: wrap; /* Allow boxes to wrap on smaller screens */
  }
  
  .testimonial-box {
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-weight: 200;
    width: 300px;
    text-align: left; /* Align text to the left */
    line-height: 29px;
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
    height: auto;
    margin-bottom: 20px; /* Add margin for better spacing on small screens */
  }
  
  .testimonial-box.highlighted {
    margin: auto 30px;
    text-align: left;
    line-height: 29px;
    font-weight: 300;
    font-size: 16px;
    max-height: 500px;
    border-radius: 20px;
    background: linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    color: white;
  }
  
  .testimonial-box p {
    margin: 0 0 10px;
    font-family: 'Poppins', sans-serif; /* Use Poppins font for paragraphs */
  }
  
  .testimonials-container h2 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-size: 50px;
    color: #093532 !important;
    letter-spacing: -0.02em;
    line-height: 1.15em;
    text-align: center;
    font-weight: 500;
  }
 footer .contact p{
    max-width: 700px;
  }
  .testimonial-box .author {
    font-weight: bold;
    color: #333;
    font-size: 15px;
  }
  
  .testimonial-box.testimonial-box.highlighted .author {
    font-weight: bold;
    color: white;
    font-size: 15px;
  }
  .footer {
    background: linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
    padding: 20px 0px 0px 0px;
    color: white;
    text-align: center;
    max-width: 100%;
    position: relative;
    margin-left: 0;
    margin-right: 0;
text-overflow: none;
    padding-top: 5% ;
    background-image: url('../Images/bbh.png'); /* Update the path to your image */
    background-size:cover;
    background-repeat: no-repeat;

    background-position: center;
    z-index: 1;

  }
  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
    z-index: -1;
}

  .footer-content {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .footer-content h2 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-size: 50px;
    justify-content: center;
    margin: auto;
    font-weight: 500;
    letter-spacing: 0.025cap;;
    text-align: center;
    width: 40%;
  }

  .contact-options {
    display: flex;
    justify-content: center;
  }
  
  .contact-option {
    text-align: center;
    text-decoration: none;
  }
  .contact-option p {
    margin: 5px 0;
  }
  
  .footer-bottom {
    margin-top: 5%;
    max-width: 100%;
    background: white;
    color: #333;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
font-size: 14px;
gap: 30px;
  }
  .contact-options .contact-link {
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Ensure link inherits text color */
    display: inline-block; /* Ensure it behaves as a block element */
}
.contacts .contact-link {
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Ensure link inherits text color */
    display: inline-block; /* Ensure it behaves as a block element */
}
.contact-option .contact-link:hover {
    text-decoration: none; /* Ensure no underline on hover */
}

  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
.facebook{
    color: rgb(49, 125, 255);
}
.instagram{
    color: rgb(227, 69, 69);
}
.contacts{
    margin-top: 50px;
display: flex;
gap: 11%;
justify-content: center;
color:white;

}
.contact{
    max-width: 15%;
        margin-bottom: 5px;

        
}
.quesomos-section .nosomos-container h4{
    color: rgb(89, 104, 240);
}
.contacts span{
    display: block;
 text-align: center;
    color:rgb(255, 255, 255);
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
}
.phone-icon {
    font-size: 1.4rem; /* Adjust the size as needed */
    margin-top: .2rem;
    margin-left: 0;
  }
    .contacts .icon{
        
        color:gold;
        margin-bottom: 20px;

        }
        .fade-in-section {
            opacity: 0;
            transform: translateY(40px);
            transition: opacity .3s ease-out, transform 1s ease-out;
        }
        
        .fade-in-section.is-visible {
            opacity: 1;
            transform: none;
        }
        /* Fade-in and Slide-in from Left */
.fade-in-left {
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 0.6s ease-out, transform 1s ease-out;
}

.fade-in-left.is-visible {
    opacity: 1;
    transform: translateX(0);
}

/* Fade-in and Slide-in from Right */
.fade-in-right {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 0.6s ease-out, transform 1s ease-out;
}

.fade-in-right.is-visible {
    opacity: 1;
    transform: translateX(0);
}

/* Fade-in and Slide-in from Bottom */
.fade-in-bottom {
    opacity: 0;
    transform: translateY(240px);
    transition: opacity .4s ease-out, transform 0.6s ease-out;
}

.fade-in-bottom.is-visible {
    opacity: 1;
    transform: translateY(0);
}

        .contact{
            text-decoration: none;
        }        .contacts p{
            margin-top: 10px;
            font-size: 14px;
            display: block;
         text-align: center;
            color:rgb(255, 255, 255);
            font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
        }

        @media (max-width: 768px) {
            .custom-image-container {
                height: 600px; /* Set the desired height */
            }
            .bubblesectioncontainer{
                flex-direction: column;
                margin-top:600px;
                margin-left: 97px;
                margin-bottom: 50px;
                left: 50px;
            }
            .custom-scrolling-image {
                position: absolute;
                top: 265px;

               width: auto;
               transform: translate(-50%, -50%);
               height: 600px;
           
            }
            .custom-overlay-container{
                display: flex;
                height: 100%;
                width: 100%;
                align-items: center;
                justify-content: center;
            }
            .custom-content-overlay {
                position: relative;
                top: -5%;
                margin: auto auto;
                left:0%;
                background: white;
                padding: 4%;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                height: 405px;
                width: 270px;
                max-width: 960px; /* Adjust width as necessary */
                max-height: 350px;
                text-align: center;
                z-index: 10;
            }
        
            .custom-content-overlay h2 {
                font-size: 21px; /* Adjust font size for mobile */
                margin-bottom: 30px;
                font-weight: 500;
            }
        
            .custom-content-overlay p {
                font-size: 14px; /* Adjust font size for mobile */
                line-height: 25px;
                font-weight: 300;
                margin: 4%;

            }
        
            .custom-content-overlay .custom-btn {
                width: 100px;
                margin-top: 15px;
                font-size: 14px; /* Adjust font size for mobile */
                padding: 13px 16px; /* Adjust padding for mobile */
            }
            
        }
        
        
            @media (max-width: 900px) {
           
          
                .mapContainer{
                    background-color: white;
                    padding:30px;
                
                }
                    .mapContainer h2{
                    font-size: 30px;
                    font-family: 'Montserrat', sans-serif !important;
                 font-weight: 500 !important;
                }
                .mapContainer h3{
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif !important;
                 font-weight: 500 !important;
                }
                .contact-options{
                    max-height: 500px;
                }
                .contact-container .contact-options{
                    position: relative;
                    bottom:-130px}
     div .info-card p{
        font-size: 12.7px;
        letter-spacing: .025cap;
      }
      div .info-card2 p{
        font-size: 12.7px;
        letter-spacing: .025cap;

      }
      div .info-card h3{
        font-size: 16px;
        letter-spacing: .025cap;

      }
      div .info-card2 h3{
        font-size: 16px;
        letter-spacing: .025cap;

            }
     .map h2{
        width: 80%;
        margin: auto;
        font-size: 20px;
        letter-spacing: .025cap;
        text-align: center; 
        font-weight: 400;

      }
  .testimonial-box {
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-weight: 200;
    width: 300px;
    text-align: left; /* Align text to the left */
    line-height: 29px;
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
    height: auto;
    margin-bottom: 20px; /* Add margin for better spacing on small screens */
  }
  
  .testimonial-box.highlighted {
    text-align: left;
    line-height: 29px;
    font-weight: 300;
    font-size: 16px;
    max-height: 500px;
    border-radius: 20px;
    background: linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    color: white;
    padding: 20px;
    margin: 0px;

  }
  .h1que{
    color: #ffffff; /* Text color */
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif; /* Font family */
    font-size: 14px; /* Font size */
    font-weight: 600; /* Font weight */
    border: 0; /* No border */
    margin-top: 20px;
    padding: 10px 18px; /* Padding */
  }
  .testimonials-container h2 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-size: 20px;
    color: #093532 !important;
    letter-spacing: -0.02em;
    line-height: 1.15em;
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
  }
   
    
    
   .content-wrapper{
    display: flex;
    justify-content: center;
    margin:auto;
   }
        .experience-container {
            display: flex;
            width:70%;
            justify-content: center;
            flex-direction: column;
            margin:auto;
            padding: 20px;
        }
    
       div .left-column{
        margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            width:100%;
            
            text-align: left;
        }
        
        .right-column {
            align-content: center;
            width:100%;
            padding-right: 0px;
            text-align: left;
            padding-left: 0px;
        }
    
        .step {
            display: flex;
            flex-direction: column;
            text-align: left;
            align-items: center;
            justify-content: center;
        max-width: 100%; 
    width: 86%;       }
    
        .step h2 {
            margin-bottom: 10px;
        }
    }
    
    @media (max-width: 700px) {
        .header h1 {
            font-size: 28px;
        }
    
        .header p {
            font-size: 14px;
        }
    
        .header button {
            font-size: 15px;
            padding: 8px 16px;
        }
    .step{
        flex-direction: row;
    }
        .step h2 {
            font-size: 28px;
        }
    
        .step h4 {
            font-size: 14px;
        }
    
        .step p {
            font-size: 14px;
        }
    
    .header h1 {
        font-size: 38px;
    }

    .header p {
        font-size: 14px;
    }

    .header button {
        font-size: 15px;
        padding: 8px 16px;
    }

    .step h2 {
        font-size: 28px;
    }

    .step h4 {
        font-size: 14px;
    }

    .step p {
        font-size: 14px;
    }

    .head-container {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
    }
    body{
        width: 100%;
        height: 100%;
    }
    .home-container{
        width: 100%;
        overflow-x: hidden;

    }
    .head-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        height: 9vh;
    border-bottom:1px solid rgba(178, 178, 178, 0.65);    width: 100%;
    }
    .content-wrapper {
        max-width: 80%;
        margin: 0 auto;
        display:flex;
        justify-content: center;
        padding: 20px;
        
    }
    nav {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        
    }
    .nav-elements {
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .logo-container {
        justify-content: left;
        width: 50%;
        margin:0;
    }
    img .logo {
        width: 100%;
    }
  

    nav ul {
        flex-direction: column;
        gap: 10px;
        display: none; /* Initially hide the menu */
        text-align: center;
        width: 100%;
        padding: 0;
        margin: 0;
        background-color: #fff; /* Background color for the dropdown menu */
        position: absolute;
        top: 100%; /* Position below the header */
        left: 0;
        z-index: 1000;
    }

    nav ul.open {
        display: block; /* Show the menu when open */
        z-index: 50;
    }

    nav ul li {
        padding: 15px 0;
        margin: auto;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #ccc; /* Optional: add a border for separation */
    }

    .hamburger {
        display: block; /* Show the hamburger button on smaller screens */
        padding-right:40px;
    
    }
    .intro{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 1000px;
        margin-top: 60px;
    }
    .leftheaderContentContainer{
        width: 100%;
    }
    .intro h4{
       text-align: start;
       font-size: 14px;
        width: 100%;
        letter-spacing:.05cap;

    }
    .intro .left{
        max-width: 100%;
    }
    .intro h1 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        color: #1a1a1a;
        margin: 0 20px 0 0;
        line-height: 30px;
        font-weight: 500;
        padding:0 0 10px;
    
    }
       .intro h1 span{
        font-size: 23px;
        font-family: 'Montserrat', sans-serif;
        color: #1a1a1a;
        margin: 0 20px 0 0;
        line-height: 30px;
        font-weight: 500;
        padding:0 0 10px;
    
    }
    .background-image {
        background-image: none !important;
        background-repeat: no-repeat;
    }
.contact-container{
    display: flex;
    justify-content: center;
    order:2;
    position: relative;        top: 503px;

}
    .contact-options {
        display: flex;
        justify-content: center ;
        margin: 50px 0 0 0 ;
        width: 500px;
        height: 500px;
        flex-direction: column;
        align-items:center ;
        
    }
    
    .contact-option a{
        display: flex;
        width: 100%;
        align-items: center;
        margin: 0 0px;
        height: 500px;
        text-decoration-line: none;

    text-align: center;
    text-decoration: none;
    }
    .leftheaderContentContainer p{
        font-size: 14px;
        font-weight: 400px;
        font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
margin-top: 15px;
    }
    .contact-option h4{
        display: flex;
        width: 100%;
justify-content: center;        margin: 0 0px;
    text-align: center;
    text-decoration-line: none;
    }
    .contact-option p{
        text-decoration-line:none !important;
    }
    contact-
    .contact-option .Path{
        color:rgb(0,85,165);
        size:60px;
        line-height: 60px;
        font-weight: 900;
        margin-bottom: 25px;
    }
    .contact-options .contact-option p{
        padding: 0px;
    margin:0px;
    line-height: 29px;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgb(102, 102, 102);
    line-height: 24px;
    width: 100%;
    text-decoration: none;

    text-decoration-line: none;

    }
div .right{
    display: flex !important;
    justify-content: center;
    max-width: 300px;
}
.video-container{
    display: flex !important;
    justify-content: center;
    height: 500px;
    position: relative;
top: -550px;   
left:0px; 
max-width: 500px;
background-repeat: no-repeat;
    
}

.video-container .iphone-video{
    width: 100%;
    background-repeat: no-repeat;

}
.info-section {
    padding: 300px 0 60px 0;
    background-color:rgb(242, 245, 247);
}

.info-cards-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width:350px;
    margin: 0 auto;
    margin-bottom: 20px; /* Optional: space between rows */
}
.leftsideimage{
    width: 100%;
}
.leftsideimage img{
    width: 200px;


}
.mapContainer h2{
    font-size: 20px !important;
}
.mapContainer h3{
    font-size: 21px !important;
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-weight:500;
}
.span-hacemos{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 16px;

    margin-top: 10px;
}
.info-card {
 
    text-align: center; /* Left align text */
    width: 100%;
    margin: 10px 40px 0px auto;
}
.info-card2 {
    text-align: center; /* Left align text */
    width: 100%;
    margin: 10px 40px 0px 0;
}
.info-card2 img {
    width: 80px;
    height: 80px;
}
.info-card img {
    width: 80px;
    height: 80px;
}
.info-card2 h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.info-card2 p {
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-weight: 300; /* Font weight 300 */
    color: rgb(107, 132, 131); /* Font color */
    font-size: 16px; /* Font size */
    line-height: 24px; /* Line height */
}
.info-card h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.info-card p {
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-weight: 300; /* Font weight 300 */
    color: rgb(107, 132, 131); /* Font color */
    font-size: 16px; /* Font size */
    line-height: 24px; /* Line height */
}


@media (max-width: 800px) {
    
.nuevavision-container{
    text-align: left;

        height: auto;
  max-width: 290px;
    margin: 60px 0 120px 0;
    margin-bottom: 30px;
display: flex;
flex-direction: column;
    border-radius: 20px;
    max-height: 1000px;
   background:linear-gradient(90deg, #5CC7EF 0%, #76aee0d1 90%);
   font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
color: white;
padding: 25px;
align-items: center;
}
.nueva-section {
    width: 100%;
    background-color:rgb(242, 245, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 275px auto 25px auto;
}
    .quesomos-container {
        text-align: left;
      width: 74.5%;
      height: auto;
      flex-direction: column;
      align-items: center;
    }
  .nosomos-container{
    text-align: left;
margin-top: 0px;
    width: 90%;
      height: auto;
      flex-direction: column;
      align-items: center;
      height: 600px;
      padding:25px;
  }
.section11 p{
    text-align: left;
    font-size: 14px !important;
    margin: 40px 0px 20px 0px;
    letter-spacing: .03cap;
    font-weight: 200;
}
.section11{
    width: 100%;
    margin: 10px  ;
}
 .section22 {
    width: 100%;
    margin: 10px ;
    }
  
    .section11 h2 {
      text-align: center;
      font-size: 1.3rem;
      margin-bottom: 20px;
      max-width: 90%;
    }
    .section11 h4 {
     color:white;
     margin: auto;
    }
    .section11 p {
      text-align: left;
      font-size: 1rem;

    }

    
   .content-wrapper{
    display: flex;
    justify-content: center;
    margin:auto;
   }
    .section11 a {
margin-bottom: 40px;    }
    div .section33 h2 {
        color: rgb(17, 16, 16);
        font-size: 30px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400px !important;

    }
    .section33 h4{
color: rgb(0, 87, 159);
    }
    .quesomos-section{
        margin-top: 25px;
        margin-bottom: 200px;
    }
 .quesomos-section .dia {
    text-align: left;
  }
    .boardrow {
      flex-direction: row;
      align-items: start;
      gap: 10px;
      margin: auto;
      margin-bottom: 44px;
    }
    .boardrow p{
      margin: 10px 0 30px 0;
      }
    .textcolum {
      align-items: center;
      text-align: center;
    }
  
    .textcolum h4 {
      font-size: 1rem;
    }
  }
  .footer-content{
    display: flex;
    flex-direction: column;
  }
  .footer-contact{
display:flex;
flex-direction: column;
  }
.map-section {
    padding: 50px 0;
    background-color: #fff;
    width: 100%;
    height: 770px;
    margin: 0 auto;
    text-align: center;
}
.footer-icon{
    color:gold;
    margin-top: 20px;
}
footer .footer-contact span {
    margin-top: 20px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
font-weight: 600px !important;
}
.map-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #121212;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 23px;
    width: 98%;

}

.map-section h3 {
    font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    font-size: 21px;
    color: #070707;
    margin-bottom: 30px;
    line-height: 24px;
    font-weight: 600;
}

.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.map-container iframe {
    width: 100%;
    max-width: 940px;
    height: 480px;
    border: none;
}
}@media (max-width: 768px) {
    
    .left-column{
        max-width: 100%;
    }
    footer{
        border-radius: 20px;
       max-width: 100%;
       margin: 0;
       background-size: cover;
       background-repeat: no-repeat;
    }
    .footer-content h2 {
      font-size: 20px;

      width: 80%;
    }
  
    .contact-options {
      flex-direction: column;
      gap: 20px;
    }
  
    footer .contacts {
        display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
    }
  
    footer .contact {
      max-width: 100%;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 20px;
      line-height: 23px;
    }
  
    .footer-bottom p {
      padding: 15px;
    }
    .testimonial-box{
    max-width: 300px;
    }
    
    }
    
    @media (max-width: 700px) {

        .intro{
            display: flex;
         
            width: 100%;
            height: 1000px;
            margin-top: 60px;
        }
        .leftheaderContentContainer{
            width: 100%;
        }
        .intro h4{
           text-align: start;
           font-size: 14px;
            width: 100%;
            letter-spacing:.05cap;
    
        }
        .intro .left{
            max-width: 100%;
        }
        .intro h1 {
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
            color: #1a1a1a;
            margin: 0 20px 0 0;
            line-height: 30px;
            font-weight: 500;
            padding:0 0 10px;
        
        }
           .intro h1 span{
            font-size: 23px;
            font-family: 'Montserrat', sans-serif;
            color: #1a1a1a;
            margin: 0 20px 0 0;
            line-height: 30px;
            font-weight: 500;
            padding:0 0 10px;
        
        }
        .background-image {
            background-image: none !important;
            background-repeat: no-repeat;
        }
    .contact-container{
        display: flex;
        justify-content: center;
        order:2;
        position: relative;        top: 503px;
    
    }
        .contact-options {
            display: flex;
            justify-content: center ;
            margin: 50px 0 0 0 ;
            width: 500px;
            height: 500px;
            flex-direction: column;
            align-items:center ;
            
        }
        
        .contact-option a{
            display: flex;
            width: 100%;
            align-items: center;
            margin: 0 0px;
            height: 500px;
            text-decoration-line: none;
    
        text-align: center;
        text-decoration: none;
        }
        .leftheaderContentContainer p{
            font-size: 14px;
            font-weight: 400px;
            font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
    margin-top: 15px;
        }
        .contact-option h4{
            display: flex;
            width: 100%;
    justify-content: center;        margin: 0 0px;
        text-align: center;
        text-decoration-line: none;
        }
        .contact-option p{
            text-decoration-line:none !important;
        }
        contact-
        .contact-option .Path{
            color:rgb(0,85,165);
            size:60px;
            line-height: 60px;
            font-weight: 900;
            margin-bottom: 25px;
        }
        .contact-options .contact-option p{
            padding: 0px;
        margin:0px;
        line-height: 29px;
        font-family: 'Poppins', 'Helvetica', 'Arial', 'Lucida', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: rgb(102, 102, 102);
        line-height: 24px;
        width: 100%;
        text-decoration: none;
    
        text-decoration-line: none;
    
        }
    div .right{
        display: flex !important;
        justify-content: center;
        max-width: 300px;
    }
    .video-container{
        display: flex !important;
        justify-content: center;
        height: 500px;
        position: relative;
    top: -550px;   
    left:0px; 
    max-width: 500px;
    background-repeat: no-repeat;
        
    }
    
    .video-container .iphone-video{
        width: 100%;
        background-repeat: no-repeat;
    
    }
    .info-section {
        padding: 300px 0 60px 0;
        background-color:rgb(242, 245, 247);
    }
    
    .info-cards-row {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width:350px;
        margin: 0 auto;
        margin-bottom: 20px; /* Optional: space between rows */
    }
    .leftsideimage{
        width: 100%;
    }
    .leftsideimage img{
        width: 200px;
    
    
    }
        footer{
            border-radius: 20px;
           max-width: 100%;
           margin: 0;
        }
        .footer-content h2 {
          font-size: 20px;
    
          width: 80%;
        }
      
        .contact-options {
          flex-direction: column;
          gap: 20px;
        }
      
        footer .contacts {
            display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: center;
        }
      
        footer .contact {
          max-width: 100%;
        }
      
        .footer-bottom {
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 20px;
          line-height: 23px;
        }
      
        .footer-bottom p {
          padding: 15px;
        }
        .testimonial-box{
        max-width: 300px;
        }
        
        }
        @media (max-width: 355px) {
    
            .left-column{
                max-width: 100%;
            }
        }
  
